.card {
  display: flex;
  flex-direction: column;

  /* border: 2px solid #32323288; */

  border-radius: 16px;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
  
  background-image: linear-gradient(#4f4f4f 10%, transparent 65%);
  text-align: center;
  
  height: 100%;
  
  padding: 1rem 1rem 2rem;
  gap: 12px;
}

.card-image {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  
  border-radius: 10px;
  /* box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25); */

  padding: 1rem;
}

.card-image img {
  height: 8vmax;
  filter: contrast(100);
}

.card-title {
  font-size: 1rem;
  font-weight: 800;
  color: #e5e6e0;
}

.card-description {
  color: #bdbdbd;
  font-size: 1rem;
  line-height: 140%;
}
