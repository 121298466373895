.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100dvh;
}

.header-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 550px;
}

.header-title-container p {
  cursor: text;
  font-size: 1.1rem;
}

.header-title-container p:hover {
  opacity: 1;
}

@media screen and (max-width: 480px) {
  .header-title-container {
    width: 350px;
  }
}
