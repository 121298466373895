

.projects-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.project:nth-child(even) {
  flex-direction: row-reverse;
}

.project:nth-child(even) .project-info {
  margin-left: 1rem;
}



.project {
  background-color: #32323223;

  border: 2px solid #32323288;
  border-radius: 16px;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
  
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 65px;
}

.project-banner {
  position: relative;
  width: 60%;
  height: 300px;
}

.project-cover {
  border-radius: 0.625rem;
  background: var(--gray-600, #323232);
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
  
  max-width: 100%;
  height: 100%;

  object-fit: cover;
}

.project-icon {
  width: 4rem;
  height: 4rem;
  position: absolute;
  right: 0;
  bottom: 0;

  margin: 24px;
}

.project-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  gap: 1rem;

  margin: 0.5rem 0;
}

.project-info h3 {
  margin: 0;
}

.project-info .project-links {
  display: flex;
  gap: 1rem;

  margin-top: auto;
}


@media screen and (max-width: 640px) {
  .projects-menu {
    scrollbar-width: none;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }

  .projects-menu:-webkit-scrollbar {
    display: none;
  }

  .projects-container {
    margin-top: 2rem;
  }

  .project {
    flex-direction: column;
    gap: 1rem;
  }

  .project h3 {
    font-size: 1.5rem;
  }

  .project:nth-child(even) {
    flex-direction: column;

  } 

  .project:nth-child(even) .project-info {
    margin-left: 0rem;
  }
  


  .project-banner,
  .project-cover {
    width: 100%;
    max-height: 180px;
  }

  .project-info {
    width: 100%;
  }
}
